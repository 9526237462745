.logs {
    border-collapse: collapse;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  
  .logs td,
  .logs th {
    border: 1px solid #ddd;
    padding: 8px;
    text-transform: capitalize;
  }
  
  .logs tr:hover {
    background-color: #ddd;
  }
  
  .logs th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
  }